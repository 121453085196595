
/* 顶部查询重置功能 */
.page_head{
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.page_head_left{
  display: flex;
  width: 80%;
  align-items: center;
}
.page_head_left input{
  width: 200px;
}

/* 主体部分 */
.page_body{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
}

/* 左侧层级菜单布局 */
.page_body_left{
  width: 400px;
}
.page_body_left_list{
  width: 350px;
  height: 100%;
  display: flex;
  overflow-y: auto;
  /* background: #f0f0f0; */
}
/* 一级 */
.page_body_left_list_firstbody{
  width: 340px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
}
/* 二级 */
.page_body_left_list_twobody{
  /* width: 320px; */
  width: 340px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* margin-left: 20px; */
}

/* 三级 */
.page_body_left_list_threebody{
  /* width: 300px; */
  width: 340px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* margin-left: 40px; */
}
/* 四级 */
.page_body_left_list_fourbody{
  /* width: 280px; */
  width: 340px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* margin-left: 60px; */
}
/* 五级 */
.page_body_left_list_fivebody{
  /* width: 260px; */
  width: 340px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* margin-left: 80px; */
}
.page_body_left_list_title{
  /* width: calc(100% - 150px); */
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  /* background: gold; */
}
.page_body_left_list_btn{
  width: 150px;
  display: flex;
  justify-content: flex-end;
}
.page_body_left_list_btn a{
  margin: 0 4px;
}

/* 右侧表格展示布局 */
.page_body_rig{
  width: calc(100% - 416px);
}

/* 新增区域 */
.page_add_modal{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page_add_modal_div{
  margin-bottom: 20px;
}
