.datasetof_page {
  width: 100%;
  height: 100%;
}
.datasetof_page_head {
  width: 100%;
  height: 50px;
  background: #dbf3ee;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  border: 1px solid #2eb998;
  color: #2eb998;
  border-radius: 5px;
}
.datasetof_page_head span {
  padding-left: 20px;
}
.datasetof_page_body {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
}
.datasetof_page_card {
  width: 23%;
  height: 330px;
  margin: 5px 20px 20px 5px;
}
.datasetof_card_child {
  width: 100%;
  height: 170px;
  overflow-y: auto;
  color: grey;
}
.datasetof_card_child_data {
  margin-bottom: 10px;
}
.datasetof_card_childadd {
  width: 100%;
  height: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20%;
  color: #2eb998;
}
.card_childadd_child {
  width: 45px;
  height: 45px;
  border: 2px solid #2eb998;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}

.site_tree_search_value {
  color: white;
  background: #3eb998;
  border-radius: 5px;
}
.datasetof_card_modal {
  max-height: 500px;
  overflow: auto;
}
