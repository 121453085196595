.newweightrecord_page {
  width: 100%;
  height: 100%;
}
.newweightrecord_search_body {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.newweightrecord_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.newweightrecord_search_body_child {
  padding: 5px 20px 5px 0;
}
.newweightrecord_page_body {
  width: 100%;
}
.newweightrecord_img {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.newweightrecord_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: rgb(230, 227, 227);
}
.newweightrecord_table_img img {
  width: 100%;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}
.rowBackground td {
  background-color: #2eb998 !important;
  color: white;
}
.weightrecord_page_search_record{
  display: flex;
}
.weightrecord_search_body_record{
  width: calc(100% - 200px);
  display: flex;
  flex-wrap: wrap;
}
.newweightrecord_search_button_record{
  width: 200px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.newweightrecord_search_button_record button {
  margin-left: 20px;
}
.newweightrecord_table_allOutput{
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-left: 20px; */
}
.weightrecord_search_body_child {
  padding: 5px 20px 5px 0;
}
