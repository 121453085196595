.loss_prevention_list_head {
  display: flex;
  /* align-items: center; */
}
.loss_prevention_list_head_search {
  width: calc(100% - 300px);
  display: flex;
  flex-wrap: wrap;
}
.loss_prevention_list_head_search_child {
  padding: 5px 20px 5px 0;
}
.loss_prevention_list_head_btn {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.loss_prevention_list_head_btn button {
  margin-left: 20px;
}
.xxx {
  display: flex;
  height: 10px;
  flex-wrap: nowrap;
  background: gold;
}
