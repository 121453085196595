.shopmanage_store_head {
  display: flex;
}
.shopmanage_store_head_search {
  width: calc(100% - 300px);
  display: flex;
  flex-wrap: wrap;
  gap:10px 20px ;
}
.shopmanage_store_head_btn {
  width: 300px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
}
.shopmanage_store_head_btn button {
  margin-left: 20px;
}

.shopmanage_store_modal {
  width: 100% !important;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shopmanage_store_modal input {
  width: 176px;
  height: 40px;
  /* margin-left: 58px; */
}
.shopmanage_store_modalicon {
  color: #19bc89;
  font-size: 15px;
  cursor: pointer;
  margin-right: 5px;
}

.shopmanage_shop_accent_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.shopmanage_shop_accent_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.shopmanage_shop_accent_input_title {
  margin-bottom: 20px;
}

.shopmanage_shop_accent_select {
  padding: 5px 20px 10px;
  display: flex;
  align-items: center;
}

.shopmanage_shop_accent_delete {
  display: flex;
  align-items: center;
}
.shopmanage_shop_accent_delete_icon {
  margin-right: 10px;
  font-size: 15px;
  color: #e6a23c;
}


.device_table_info {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
