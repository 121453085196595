.camera_page {
  width: 100%;
  height: 100%;
}
.camera_page_head {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.camera_search {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.camera_page_head_store_search {
  padding: 5px 20px 5px 0;
}
.camera_page_btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.camera_page_btn button {
  margin-left: 20px;
}

/* // */
.camera_tag {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.camera_tag button {
  margin-right: 20px;
  background: rgba(46, 185, 152, 0.1);
  color: #19bc89;
  border-color: #19bc89;
}

/* // */
.camera_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.camera_content_left {
  width: 45%;
}

.camera_content_right {
  width: 45%;
}
.camera_content_right_title {
  display: flex;
}
.camera_content_right_title_one {
  font-size: 16px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-right: 16px;
}
.camera_content_right_title_two {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}
.camera_content_right_title_three {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #409eff;
}
.camera_content_right_body {
  width: 100%;
  height: 500px;
  background: #f6f6f6;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  margin-top: 16px;
}
