.page_login {
  width: 100%;
  height: 100%;
  display: flex;
  /* background-image: url(../../images/loginlogo.png); */
  /* background-repeat: no-repeat; */
  /* background-size: cover; */
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.page_login_left_body {
  width: 40%;
  height: 100%;
  /* background-image: url(../../images/shs.jpg);
  background-repeat: no-repeat;
  background-size: cover; */
}
.page_login_right_body {
  width: 60%;
  height: 100vh;
}
.page_login_right_body img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.page_login_body {
  width: 100%;
  height: 100%;
}

.page_login_right {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-items: center; */
}
.page_login_leflogo {
  width: 100%;
}
.page_login_leflogo img {
  width: 180px;
  height: 40px;
  object-fit: cover;
  margin: 40px 0 25% 8%;
}
.page_login_user {
  width: 70%;
}
.page_login_tabs {
  width: 100%;
  display: flex;
  justify-content: center;
}
.page_login_tabs_child {
  width: 300px;
}
.page_user_input {
  width: 100%;
  /* height: 200px; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_user_body {
  width: 300px;
  /* height: 200px; */
  display: flex;
  flex-direction: column;
}
.page_user_child {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page_userchild_body {
  display: flex;
  justify-content: space-between;
}
.page_login_yanzm {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.page_user_child img {
  width: 100px;
  height: 33px;
  object-fit: cover;
}

.page_userchild_btn {
  width: 100px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page_user_btn {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page_user_btn button {
  width: 300px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_login_other {
  width: 300px;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  font-size: 12px;
  color: #2eb998;
}
.page_login_other a {
  cursor: pointer;
}
/* .ant-btn-primary {
  border: none !important;
} */
.page_forgetLogin {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.page_forgetLogin_head {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.page_forgetLogin_body {
  width: 100%;
  height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.page_forgrt_input {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_userchild_image img {
  width: 100px;
  height: 30px;
  object-fit: cover;
}
.page_forget_btn {
  width: 100%;
  height: 136px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
}
.page_forget_btn button {
  width: 300px;
}
.err_text {
  color: rgb(238, 68, 68);
  font-size: 12px;
}
.err_text_password {
  color: rgb(238, 68, 68);
  font-size: 12px;
  padding-left: 15px;
}

.page_login_title {
  font-size: 23px;
  font-family: OPPOSans-Bold, OPPOSans;
  font-weight: bold;
  color: #333333;
  margin-bottom: 50px;
}
