.home_page {
  width: 100%;
  height: 100%;
}
.home_page_head {
  width: 100%;
  height: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home_head_tab {
  display: flex;
  align-items: center;
}
.home_head_tab div {
  height: 25px;
  display: flex;
  align-items: center;
  margin: 0 50px 0 20px;
  cursor: pointer;
}

.home_head_date {
  display: flex;
}

.home_body_info {
  margin-top: 20px;
}
.home_body_info {
  display: flex;
  justify-content: space-between;
}

.home_info_child {
  width: 24%;
  height: auto !important;
}
.home_child_detail {
  display: flex;
  align-items: center;
}
.home_child_detail span {
  color: #2eb998;
}
.home_detail_title {
  padding: 0 5px 0 0;
  cursor: pointer;
  color: #2eb998;
}

.home_card_child {
  width: 32.5%;
  height: auto !important;
}

.home_card_child_table {
  width: 49.5%;
  height: auto !important;
}

.home_card_piehart {
  width: 100%;
  /* height: 300px; */
}

.home_card_tabs {
  width: 100%;
}
.piehart_card{
  width: 100%;
  display: flex;
}
.piehart_echart{
  width: 60%;
}
.piehart_echartbtn{
  width: 100%;
  display: inline-flex;
  flex-wrap: wrap;
  gap:10px 20px;
  color: grey;
}
.piehart_echartbtn span{
  cursor: pointer;
}
.piehart_body{
  width: 100%;
}
.piehart_info{
  width: 40%;
  display:inline-flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content:center;
  gap: 20px;
  font-weight: 400;
  font-size: 13px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  background: gold; */
}
.piehart_info_Info{
  width: 100%;
  height: 100%;
  display:inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content:center;
  gap: 20px;
  font-weight: 400;
  font-size: 13px;
  color: #333333;
  font-style: normal;
  text-transform: none;
  /* margin-top: 80px; */
}
.piehart_info_slect{
  width: 100%;
  height: 50px;
}
.piehart_infospan{
  width: 14px;
  height: 14px;
  background: #39BE87;
  border-radius: 4px;
}

.home_card_tabs span {
  color: grey;
  margin-right: 20px;
  cursor: pointer;
}

.home_info_child_span {
  font-size: 30px;
  font-weight: bold;
  margin-right: 2px;
}
.home_page_overview {
  width: 100%;
  height: 137px;
  display: flex;
  justify-content: space-between;
}
.home_page_overview_child {
  width: 24.2%;
  height: 137px;
  border-radius: 8px 8px 8px 8px;
  opacity: 0.9;
  border: 1px solid #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.home_page_overview_child_body {
  width: 80%;
  height: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home_page_overview_child_body_left {
  max-width: calc(100% - 68px);
}
.child_body_left_shoptotal {
  font-size: 14px;
  font-family: OPPOSans, OPPOSans;
  font-weight: bold;
  color: rgba(8, 26, 18, 0.6);
}
.child_body_left_shopNum {
  font-size: 28px;
  font-family: OPPOSans-Bold, OPPOSans;
  font-weight: bold;
  color: rgba(8, 26, 18, 0.9);

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.child_body_left_shopNum span {
  font-size: 14px;
  font-family: OPPOSans-Regular, OPPOSans;
  font-weight: 400;
  color: #081a12;
  margin-left: 10px;
}
.child_body_left_shopDetail {
  font-size: 13px;
  font-family: OPPOSans-Regular, OPPOSans;
  font-weight: 400;
  color: #2eb998;
  cursor: pointer;
}
.child_body_left_shopIcon {
  margin-left: 4px;
}

.home_page_overview_child_body_right img {
  width: 68px;
  height: 68px;
  object-fit: contain;
}

/* 底部主体内容 */
.home_page_date {
  display: flex;
  justify-content: space-between;
}
.home_page_date_tab {
  display: flex;
}
.home_tab {
  width: 60px;
  height: 32px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #dcdfe6;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}
.home_tab_check {
  width: 60px;
  height: 32px;
  background: #2eb998;
  color: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  cursor: pointer;
}

/* 图表展示 */



/* 预警设置 */
.pageModalHead{
  background: #FCF6EC;
  border-radius: 4px;
  font-family: OPPOSans, OPPOSans;
  font-weight: 400;
  font-size: 12px;
  color: #E6A23C;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.pageModalBody{
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}
.pageModalBoody{
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.pageModalBoodyTitle{
  width: 100px;
  display: flex;
  justify-content: flex-end;
}