/* 顶部步骤条 */

.createpage_head {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.createpage_head_steps {
  width: 60%;
}

/* 步骤条第一步 */
.createpage_head_one {
  width: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
}

.createpage_head_one_body {
  width: 300px;
  height: 70px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

/* 步骤条第二步 */
.createpage_head_twotag {
  display: flex;
  align-items: center;
}

.createpage_head_two_search {
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.createpage_head_two_inputbody {
  display: flex;
  flex-wrap: wrap;
}
.createpage_head_two_input {
  padding: 5px 20px;
}
.createpage_head_two_btn {
  width: 150px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}

.createpage_head_two_next {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.createpage_head_two_success_icon {
  color: #2eb998;
  font-size: 63px;
}

.createpage_head_two_success {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-family: OPPOSans-Medium, OPPOSans;
  font-weight: 500;
  color: rgba(8, 26, 18, 0.9);
}
.createpage_head_two_success_span {
  margin: 20px 0 40px 0;
}

.createpage_share_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
