.shop_goods_info_head {
  display: flex;
  /* align-items: center; */
}
.shop_goods_info_head_search {
  width: calc(100% - 180px);
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}

.shop_goods_info_head_btn {
  width: 180px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  gap: 20px;
}


/* 查询下发图片 */

.modalody{
  width: 100%;
  height: 420px;
  display: flex;
  /* align-items: center; */
  justify-content: center;
}
.modalodyImgBody{
  width: 400px;
  height: 100%;
}
.modalodyImg{
  width: 100%;
  height: 400px;
  background: #F5F7FA;

}
.modalodyTitle{
  width: 100%;
  height: 20px;
  display: flex;
  justify-content: flex-end;
}
.pageModalChildImgBodyDivLeBtn{
  width: 40px;
  height: 40px;
  background: rgba(46, 185, 152, 0.2);
  position: absolute;
  left: 20px;
  top:calc(calc(100%  - 40px)/2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pageModalChildImgBodyDivRiBtn{
  width: 40px;
  height: 40px;
  background: rgba(46, 185, 152, 0.2);
  position: absolute;
  right: 20px;
  top:calc(calc(100%  - 40px)/2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
