.performance_page {
  width: 100%;
  height: 100%;
}
.weightdatabody_performance_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
}
.weightdatabody_performance_search_body {
  width: calc(100% - 300px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.performance_search_body_child {
  padding: 5px 20px 5px 0;
  display: flex;
  align-items: center;
}

.ribao {
  width: 60px;
  height: 32px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #dcdfe6;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  color: #081a12;
  margin-right: 10px;
  cursor: pointer;
}
.ribao_check {
  width: 60px;
  height: 32px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px solid #2eb998;
  border-radius: 8px 8px 8px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  color: #ffffff;
  margin-right: 10px;
  cursor: pointer;
  background: #2eb998;
}

.performance_page_body {
  width: 100%;
}
.performance_body_export {
  width: 100%;
  height: 60px;
  display: flex;
}
.weightdatabody_performance_search_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.weightdatabody_performance_search_button button {
  margin-left: 20px;
}
.weightdata_table_single{
  width: 100%;
  display: flex;
  align-items: center;
}
.custom_time_export{
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
