.code_size_head {
  display: flex;
}
.code_size_head_body {
  width: calc(100% - 200px);
  display: flex;
  flex-wrap: wrap;
}

.code_size_head_search_button_record{
  width: 200px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.code_size_head_search_button_record button {
  margin-left: 20px;
}

.code_size_head_search_body_child {
  padding: 5px 20px 5px 0;
}

.code_size_buttons {
  display: flex;
  justify-content: space-between;
}
.code_size_buttons_left button {
  margin-right: 20px;
}
.code_size_buttons_left_onbtn {
  background: #e8f5ef;
  color: #39be87;
  border-color: #39be87;
}
.code_size_table_img {
  height: 100px;
}
.code_size_table_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.code_size_modal {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.code_size_modal_child {
  width: calc(100% - 170px);
}
.code_size_modal_child_doladbtn {
  display: flex;
  justify-content: flex-end;
}

.code_size_modal_child :global(.ant-upload-list-item-container){
  display: none !important;
}
.code_size_modal_child :global(.ant-upload-list){
  display: none !important;
}
.code_size_modal_child .ant-upload-list {
  display: none;
}
.code_size_modal_child_span {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 12px;
  color: #606266;
  margin: 6px 0;
}
.code_size_filelist{
  width: 100%;
  display: flex;
  flex-direction: column;
}
.code_size_filelist_child{
  margin: 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.code_size_filelist_child_title{
  width: 90%;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}
