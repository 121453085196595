.devicedetail_page {
  width: 100%;
  height: 100%;
}
.devicedetail_search_body {
  display: flex;
  align-items: center;
}
.devicedetail_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: wrap;
}
.devicedetail_page_body {
  width: 100%;
}
