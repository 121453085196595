/* 新图片展示Modal */
.policeroad_imgmodal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.policeroad_imgmodal_left {
  width: 48%;
  height: 520px;
  background: #f5f7fa;
  overflow: auto;
  position: relative;
  /* overflow: hidden; */
}
/* .policeroad_imgmodal_right1 {
  width: calc(100% - 540px);
  height: 550px;
  overflow: auto;
}
.policeroad_imgmodal_left1 {
  width: 520px;
  height: 500px;
  background: #f5f7fa;
  overflow: auto;
  position: relative;
  margin-top: 60px;
} */
.policeroad_imgmodal_leftmenceng{
  width: 100%;
  height: 520px;
  background:#f5f7fa;
  top: 0;
  left: 0;
  z-index: 999999;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
/* #video-container {
  margin-top: -500px;
} */

.policeroad_imgmodal_leftsb {
  width: 100%;
  height: 450px;
  background: #ecf5ff;
  overflow: auto;
  display: flex;
  align-items: center;
}
.policeroad_imgmodal_left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.policeroad_imgmodal_left video {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.policeroad_imgmodal_right {
  width: 48%;
  height: 520px;
  overflow: auto;
}
.policeroad_imgmodal_right::-webkit-scrollbar {
  width: 10px; /* 滚动条宽度 */
}

.policeroad_imgmodal_right::-webkit-scrollbar-track {
  background: #f1f1f1; /* 滚动条轨道背景色 */
}

.policeroad_imgmodal_right::-webkit-scrollbar-thumb {
  background: #888 #ecf5ff; /* 滑块背景色 */
  border-radius: 5px; /* 滑块圆角 */
}

.policeroad_imgmodal_right::-webkit-scrollbar-thumb:hover {
  background: #888; /* 滑块悬停时的背景色 */
}
.policeroad_imgmodal_right_list {
  display: flex;
}
.policeroad_imgmodal_right_listleft {
  min-width: 127px;
  min-height: 40px;
  background: #f5f7fa;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #e4e7ed;
  color: #606266;
  display: flex;
  align-items: center;
}
.policeroad_imgmodal_right_fenge {
  width: 100%;
  height: 40px;
  background: #f5f7fa;
  border-left: 1px solid #e4e7ed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.policeroad_imgmodal_right_listright {
  min-width: calc(100% - 127px);
  min-height: 40px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px 0px #ebeef5;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #e4e7ed;
  border-bottom: none;
  display: flex;
  align-items: center;
}
.policeroad_imgmodal_right_listright1 {
  min-width: calc(100% - 127px);
  min-height: 40px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px 0px #ebeef5;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  border: 1px solid #e4e7ed;
  display: flex;
  flex-direction: column;
}
.policeroad_imgmodal_right_list span {
  margin-left: 16px;
}
.policeroad_imgmodal_right_listright1 span {
  padding: 5px 0;
}

.policeroad_page {
  width: 100%;
  height: 100%;
}

.policeroadbody_policeroad_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
}
.policeroadbody_policeroad_search_body {
  width: calc(100% - 180px);
  display: flex;
  flex-wrap: wrap;
}
.policeroadbody_policeroad_search_body_child {
  padding: 5px 20px 5px 0;
}
.policeroadbody_policeroad_search_button {
  width: 180px;
  padding: 5px 0;
  display: flex;
  justify-content: flex-end;
}
.policeroadbody_policeroad_search_button button {
  margin-left: 20px;
}
.policeroad_page_body {
  width: 100%;
}
.policeroad_img {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.policeroad_img img {
  width: auto;
  height: 100%;
  object-fit: contain;
  background: rgb(230, 227, 227);
}
.policeroad_table_img img {
  width: 100%;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}
.policeroad_modal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.policeroad_modal img {
  width: 400px;
  height: 400px;
  object-fit: contain;
}
.policeroad_modal_img {
  width: 50px;
  height: 50px;
  /* height: auto; */
  object-fit: contain;
}
.policeroad_allOutput {
  width: 100%;
  display: flex;
  align-items: center;
  /* margin-left: 20px; */
}

.policeroad_sbtitle {
  width: 100%;
  height: 40px;
  display: flex;
  background: #f5f7fa;
}

.policeroad_sbtitle_one {
  width: 127px;
  text-align: center;
  line-height: 40px;
  border: 1px solid #e4e7ed;
}
.policeroad_sbtitle_two {
  width: calc(calc(100% - 127px) / 4);
  text-align: center;
  line-height: 40px;
  border: 1px solid #e4e7ed;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.colums_title{
  display: flex;
}
.colums_title_checkBox{
  padding: 10px;
}
.play_btn{
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  gap:20px;
  cursor: pointer;
}
.playicon{
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border:1px solid #d9d9d9;
  background: #ffffff;
}
.title_div{
  width: calc(100% - 18px);
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap:0 20px;
  margin-left: 18px;
}