.page_share_head {
  display: flex;
}
.page_share_head_left {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
}
.page_share_head_left_child {
  padding: 5px 20px 5px 0;
}
.page_share_head_right {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.page_share_head_right button {
  margin-left: 20px;
}
.page_share_drawer {
  padding-bottom: 20px;
  font-weight: bold;
  color: #19bc89;
  font-size: 16px;
}
.page_table_deviceList {
  color: #19bc89;
  cursor: pointer;
}
/* .page_share_select_fuck {
  margin-bottom: 180px;
} */
