.shop_goods_info_head {
  display: flex;
  align-items: center;
}
.shop_goods_info_head_search {
  width: calc(100% - 300px);
  display: flex;
}
.shop_goods_info_head_search_child {
  padding: 5px 20px 5px 0;
}
.shop_goods_info_head_btn {
  width: 300px;
  display: flex;
  justify-content: flex-end;
}
.shop_goods_info_head_btn button {
  margin-left: 20px;
}

.shop_goods_info_modal {
  width: 100% !important;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shop_goods_info_modal input {
  width: 176px;
  height: 40px;
  /* margin-left: 58px; */
}
.shop_goods_info_modalicon {
  color: #19bc89;
  font-size: 15px;
  cursor: pointer;
  margin-right: 5px;
}

.shopmanage_shop_accent_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.shopmanage_shop_accent_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.shopmanage_shop_accent_input_title {
  margin-bottom: 20px;
}

.shopmanage_shop_accent_select {
  padding: 5px 20px 10px;
  display: flex;
  align-items: center;
}

.shopmanage_shop_accent_delete {
  display: flex;
  align-items: center;
}
.shopmanage_shop_accent_delete_icon {
  margin-right: 10px;
  font-size: 15px;
  color: #e6a23c;
}

.shop_goods_info_head_btn_upload {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.shop_goods_info_modal_a{
  margin-top: 20px ;
  color: orange;
}
.shop_goods_info_modal_a a{
  color: rgb(221, 75, 17);
  text-decoration: underline;
}
.shop_goods_info_modal_card{
  margin-top: 16px;
}

.shop_goods_info_modal_card :global(.ant-upload-list-item-container){
  display: none !important;
}
.shop_goods_info_modal_card :global(.ant-upload-list){
  display: none !important;
}

.shop_goods_info_modal_card_div{
  display: flex;
  align-items: center;
  /* justify-content: center; */
  margin-top: 20px;
}
.shop_goods_info_modal_card_div_title{
  font-weight: bold;
}
.card_div_btn{
  margin-left: 10px;
}

.shop_goods_info_shop {
  /* padding: 0 0 20px 20px; */
  display: flex;
  align-items: center;
  margin-top: 16px;
}
.shop_goods_info_shop_span {
  color: #f56c6c;
  margin-right: 3px;
}