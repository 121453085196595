.add_loss_body {
  width: 100%;
  height: calc(100vh - 180px);
  overflow: auto;
}
.add_loss_body_child {
  padding: 0 0 20px 20px;
  display: flex;
  align-items: center;
}
.add_loss_body_child_span {
  color: #f56c6c;
  margin-right: 3px;
}

.add_loss_body_child_body {
  margin-left: 23px;
}
.add_loss_body_child_time {
  flex-direction: column;
  margin-top: -5px;
}
.add_loss_body_child_time_child {
  width: 100%;
  display: flex;
  align-items: center;
}
.add_loss_body_child_white_search {
  margin-bottom: 20px;
}
.add_loss_body_child_white_search button {
  margin-left: 20px;
}

/* // */
.add_loss_body_child_laji {
  display: flex;
  width: 100%;
}
.add_loss_body_child_laji_title {
  display: flex;
  /* align-items: center; */
  padding: 0 0 0 20px;
  min-width: 100px;
}
.add_loss_body_child_laji_child {
  display: flex;
  align-items: center;
  padding: 0 0 20px 5px;
  flex-wrap: wrap;
}
.add_loss_body_child_laji_body {
  width: calc(100% - 200px);
  margin-top: -10px;
}
.tag_body {
  margin-left: 20px;
  height: 32px;
  display: flex;
  align-items: center;
  border-radius: 8px 8px 8px 8px;
}
.add_loss_body_child_input{
  display: flex;
  align-items: center;
  color: #666666;
}