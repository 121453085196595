.devicemanage_page_store_head {
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}
.page_store_head button {
  height: 37px;
  /* margin-left: 20px; */
}
.page_store_head input {
  width: 176px;
  height: 40px;
  /* margin-left: 58px; */
}
.devicemanage_page_page_store_search {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.devicemanage_page_store_head_store_search {
  padding: 5px 20px 5px 0;
}
.devicemanage_page_store_btn {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.devicemanage_page_store_btn button {
  margin-left: 20px;
}
.page_store_modal {
  width: 100% !important;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_store_modal input {
  width: 176px;
  height: 40px;
  /* margin-left: 58px; */
}
.page_store_modalicon {
  color: #19bc89;
  font-size: 15px;
  cursor: pointer;
  margin-right: 5px;
}
.page_edit_device_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page_edit_device_modal_child {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.page_edit_device_modal_childname {
  width: 100px;
  display: flex;
  flex-direction: row-reverse;
}
.page_edit_device_modal_childname span {
  margin-right: 5px;
  color: #f56c6c;
}
.page_edit_device_modal_child .ant-input-affix-wrapper {
  border: 1px solid #dcdfe6 !important;
  /* height: 40px; */
}
/* 
.page_store_accent_select {
  padding: 5px 20px 10px;
  display: flex;
  align-items: center;
}
.page_store_accent_select .ant-select-selector {
  padding: 5px !important;
}

.page_store_accent_delete {
  display: flex;
  align-items: center;
}
.page_store_accent_delete_icon {
  margin-right: 10px;
  font-size: 15px;
  color: #e6a23c;
} */
.devicemanage_page_store_caozuo {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
}
.devicemanage_page_store_caozuo button {
  margin-right: 20px;
  background: rgba(46, 185, 152, 0.1);
  color: #19bc89;
  border-color: #19bc89;
}
.devicemanage_page_store_table_btn {
  display: flex;
  /* flex-direction: column; */
}
.devicemanage_page_store_table_btn a {
  padding: 0 5px;
}
