.page_all_body {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
/* .page_all_body
  :where(.css-dev-only-do-not-override-o0odhm).ant-menu-light.ant-menu-submenu
  > .ant-menu {
  background: red !important;
} */
.ant-menu-light.ant-menu-submenu > .ant-menu {
  background: #f1fbf3 !important;
  color: black;
}

/* 左侧menu */
.page_menu {
  background: rgba(0, 0, 0, 0);
  border-right: 0;
  color: black;
}
/* .page_menu_item
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background-color: red;
} */

/* 右侧主体布局 */
.page_count {
  /* width: calc(100% - 200px) !important; */
  height: 100%;
}

/* 顶部内容 */
.page_count_header {
  width: 100%;
  height: 60px !important;
  background: linear-gradient(137deg, #f1fbf3 0%, #e5f7fb 100%) !important;
  box-shadow: 0px 4px 6px 1px rgba(204, 204, 204, 0.16) !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
header.ant-layout-header.page_count_header {
  padding: 0 10px !important;
}
.page_count_header_logo {
  width: 200px;
}
.page_count_header_logo img {
  width: 180px;
  /* padding: 20px; */
  object-fit: contain;
}
.page_count_threepage {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
}
.page_count_layout {
  margin: 0 16px 16px 16px;
  width: calc(100% - 32px);
}

.page_count_content {
  width: 100%;
  height: 100%;
  /* background: #fff; */
  /* padding: 20px; */
  border-radius: 5px;
  overflow: auto;
}
.page_count_user {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* 顶部左侧页面标题 */
.page_count_breadcrumb {
  padding-left: 20px;
  font-weight: bold;
}
.page_count_user .ant-breadcrumb{
  cursor: not-allowed; /* 更改鼠标指针 */
  pointer-events: none; /* 禁用点击事件 */
}
.ant-breadcrumb .ant-breadcrumb-link{
  color: rgb(84, 82, 82);
}
.page_count_breadcrumb_goback {
  height: 60px;
  display: flex;
  align-items: center;
  color: #19bc89;
  font-weight: bold;
  margin:0 16px 0 20px;
  cursor: pointer;
}

/* 顶部右侧操作提示 */
.page_count_user img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  margin-right: -5px;
}
.page_count_user button {
  color: black;
}
.page_sider_page {
  width: 100px;
  background: #fff;
}

.page_count_icon {
  height: 60px;
  display: flex;
  align-items: center;
}
.page_count_user_icon {
  font-size: 20px;
  /* margin-right: 30px; */
}
.page_count_icon_fenge {
  padding: 0 10px 0 15px;
}

.ant-menu-submenu-title {
  color: black !important;
}
/* 重点 */
/* 有子集的页面悬颜色 */
.ant-menu-submenu-title:hover{
  color: white !important;
  background: #2eb998 !important;
}
/* 单页面悬浮颜色 */
.ant-menu-item:hover{
  color: white !important;
  background: #2eb998 !important;
}
/* 选中以后得颜色 */
.ant-menu-item-selected{
  color: white !important;
  background: #2eb998 !important;
}
/* 重点 */
.ant-menu-light {
  border: none !important;
}

.upload_pass {
  width: 100%;
  height: 100%;
}
