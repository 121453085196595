
.data_share_lable {
  display: flex;
  margin: 5px 0 20px 0;
  align-items: center;
  flex-wrap: wrap;
}
.data_share_lable_title {
  min-width: 100px;
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
  align-items: center;
  color: #303133;
}
.data_share_lable_title span {
  color: #f56c6c;
  font-size: 15px;
  margin-right: 5px;
}
.data_share_configdata_tag {
  height: 32px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.data_share_device_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.data_share_device_search_left {
  display: flex;
  width: calc(100% - 150px);
  flex-wrap: wrap;
}
.data_share_device_search_left_childs {
  margin-right: 20px;
  padding: 5px 0;
}

.data_share_device_search_right {
  width: 150px;
  padding: 5px 0;
}
.data_share_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.data_share_modal_child {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 20px 0;
}

.data_share_image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
