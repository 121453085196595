.loss_prevention_list_head {
  display: flex;
  /* align-items: center; */
}
.loss_prevention_list_head_search {
  width: calc(100% - 200px);
  display: flex;
  flex-wrap: wrap;
}
.loss_prevention_list_head_search_child {
  padding: 5px 20px 5px 0;
}
.loss_prevention_list_head_btn {
  width: 200px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.loss_prevention_list_head_btn button {
  margin-left: 20px;
}
.xxx {
  display: flex;
  height: 10px;
  flex-wrap: nowrap;
  background: gold;
}
.page_table_img{
  width: 100%;
  height: 80px;
  object-fit:contain;
}
.policeroad_imgmodal {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.policeroad_imgmodal_left {
  width: 100%;
  height: 600px;
  background: #f5f7fa;
  overflow: auto;
  /* overflow: hidden; */
}
.page_table_select :global(.ant-select-selector .ant-select-selection-item){
  color:red
}
.colums_title{
  display: flex;
}
.colums_title_checkBox{
  padding: 10px;
}
.play_btn{
  width: calc(100% - 32px);
  height: 50px;
  display: flex;
  align-items:center;
  gap:20px;
  cursor: pointer;
  margin-left: 32px;
}
.playicon{
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border:1px solid #d9d9d9;
  background: #ffffff;
}
.title_div{
  width: calc(100% - 18px);
  height: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap:0 20px;
  margin-left: 18px;
}
.policeroad_imgmodal_leftmenceng{
  width: 100%;
  height: 600px;
  background:#f5f7fa;
  top: 0;
  left: 0;
  z-index: 999999;
  display:flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}