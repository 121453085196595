.weightrecord_page {
  width: 100%;
  height: 100%;
}
.weightrecord_search_body {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.weightrecord_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 20px;
  /* flex-wrap: wrap; */
}
.weightrecord_search_body_child {
  padding: 5px 20px 5px 0;
}
.weightrecord_page_body {
  width: 100%;
}
.weightrecord_img {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.weightrecord_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background: rgb(230, 227, 227);
}
.weightrecord_table_img img {
  width: 100%;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}
.rowBackground td {
  background-color: #2eb998 !important;
  color: white;
}
.weightrecord_search_button {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.weightrecord_search_button button {
  margin-left: 20px;
}
