.goodDetail_page {
  width: 100%;
  height: 100%;
}
.goodDetail_head_brn {
  width: 100%;
  display: flex;
  flex-direction: row-reverse;
}
.goodDetail_head_brn button {
  margin-left: 10px;
}
.goodDetail_page_time {
  display: flex;
  margin-left: 10px;
}
.goodDetail_page_shop {
  display: flex;
  margin-left: 20px;
  align-items: center;
}
.goodDetail_page_shop_input {
  width: 200px;
}
