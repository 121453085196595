.mergerecords_page {
  width: 100%;
  height: 100%;
}

.mergerecords_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
}
.mergerecords_search_body {
  width: 80%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.mergerecords_search_body_child {
  margin: 0 20px 0 0;
}

.mergerecords_page_body {
  width: 100%;
}
.mergerecords_img {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mergerecords_img img {
  width: auto;
  height: 100%;
  object-fit: contain;
  background: rgb(230, 227, 227);
}
.mergerecords_table_img img {
  width: 100%;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}
