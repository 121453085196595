.look_import_head {
  display: flex;
}
.look_import_head_body {
  width: calc(100% - 200px);
  display: flex;
  flex-wrap: wrap;
}
.look_import_head_exporttime{
  margin-bottom: 16px;
  color:darkgrey;
}
.look_import_head_exporttime span{
  margin-right: 20px;
}
.look_import_head_button_record{
  width: 200px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.look_import_head_button_record button {
  margin-left: 20px;
}
.look_import_head_search_body_child {
  padding: 5px 20px 5px 0;
}