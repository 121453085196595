.page_shopmanage_store_head {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.page_shopmanage_store_head button {
  margin-left: 20px;
}
.page_shopmanage_store_head input {
  width: 176px;
  /* margin-left: 58px; */
}
.page_store_modal {
  width: 100% !important;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_store_modal input {
  width: 176px;
  height: 40px;
  /* margin-left: 58px; */
}
.page_store_modalicon {
  color: #19bc89;
  font-size: 15px;
  cursor: pointer;
  margin-right: 5px;
}

.shop_accent_title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.shop_accent_modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.shop_accent_input_title {
  margin-bottom: 20px;
}
.shop_accent_input_title .ant-input-affix-wrapper {
  border: 1px solid #dcdfe6 !important;
  height: 40px;
}

.shop_accent_select {
  padding: 5px 20px 10px;
  display: flex;
  align-items: center;
}
.shop_accent_select .ant-select-selector {
  padding: 5px !important;
}

.shop_accent_delete {
  display: flex;
  align-items: center;
}
.shop_accent_delete_icon {
  margin-right: 10px;
  font-size: 15px;
  color: #e6a23c;
}
