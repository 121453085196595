.weight_page {
  width: 100%;
  height: 100%;
}

.errorweightbody_weight_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.errorweightbody_weight_search_body {
  width: calc(100% - 300px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.weight_page_body {
  width: 100%;
  margin-top: 20px;
}
.weight_body_export {
  width: 100%;
  height: 60px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.errorweightbody_weight_search_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.errorweightbody_weight_search_button button {
  margin-left: 20px;
}
.errorweightbody_weight_search_body_child {
  padding: 5px 20px 5px 0;
}
