.page_datashare_page {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.page_datashare_img {
  width: 100%;
  height: 50px;
  background: #fffbe6;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #ffe58f;
  display: flex;
  align-items: center;
  color: #000000;
  font-size: 14px;
}
.page_datashare_icon {
  font-size: 20px;
  color: #faad14;
  opacity: 1;
  padding: 0 27px;
}

.page_datashare_steps {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 15px;
}
.page_steps_first {
  padding: 30px 0;
}
.page_steps_span {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.85);
  margin-left: 20px;
}
.page_steps_a {
  color: #19bc89;
  margin-left: 30px;
}
.page_steps_second {
  width: 80%;
  height: 500px;
  overflow: auto;
  padding: 30px 0;
  display: flex;
}
.steps_second_left {
  width: 500px;
  border: 1px solid rgb(212, 204, 204);
}
.second_left_head {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid rgb(212, 204, 204);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.second_left_head input {
  width: 70%;
  margin-right: 20px;
}
.left_head_left {
  display: flex;
  padding: 20px;
  justify-content: center;
  align-items: center;
}
.second_left_body {
  padding: 10px;
}
.steps_second_btn {
  width: 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.steps_second_right {
  width: 500px;
  border: 1px solid rgb(212, 204, 204);
}

.page_datashare_submit {
  width: 85%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_datashare_submit button {
  width: 142px;
  height: 41px;
}

.page_equipment {
  width: 100%;
  display: flex;
  justify-content: center;
}
.page_tree_left {
  min-width: 400px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  margin-top: 20px;
  max-height: 400px;
  min-height: 200px;
  overflow: auto;
}
.page_tree_right {
  min-width: 400px;
  border: 1px solid gainsboro;
  border-radius: 5px;
  margin-top: 20px;
  max-height: 400px;
  min-height: 200px;
  overflow: auto;
}
.page_treeright_child {
  padding: 5px 10px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_rigtitle {
  height: 35px;
  display: flex;
  align-items: center;
}
.page_online {
  font-size: 50px;
  margin-right: 5px;
  color: #1890ff;
  margin-bottom: 5px;
}
.page_tree_nodate {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(196, 192, 192);
}
.page_onlien_status {
  display: flex;
  height: 20px;
  align-items: center;
  margin-left: 20px;
}
.page_online1 {
  font-size: 50px;
  margin-right: 5px;
  color: #1890ff;
  margin-bottom: 5px;
}
.page_online2 {
  font-size: 50px;
  color: red;
  margin: 0 5px 5px 20px;
}
.page_tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}

.page_equipment {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tree_left_body {
  width: 40%;
  height: 450px;
  border: none;
}
.discer_tree_head {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tree_head_text {
  font-size: 16px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #333333;
}
.tree_head_state {
  min-width: 30%;
  display: flex;
  justify-content: space-between;
}
.tree {
  height: 340px;
  padding: 10px 10px 10px 10px 0;
  overflow: auto;
  border: 1px solid #bdb8b8;
}
.treeSubOrClearBtn {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tree_right_body {
  width: 40%;
  height: 450px;
  overflow: none;
  /* border: 1px solid #bdb8b8; */
  border: none;
}
.page_treeright_child {
  padding: 5px 10px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_rigtitle {
  height: 35px;
  display: flex;
  align-items: center;
}
.page_online {
  padding: 0 5px 5px 10px;
}
.page_tree_nodate {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(196, 192, 192);
}
.page_onlien_status {
  display: flex;
  height: 20px;
  align-items: center;
  margin-left: 20px;
}
.page_online1 {
  font-size: 50px;
  margin-right: 5px;
  color: #1890ff;
  margin-bottom: 5px;
}
.page_online2 {
  font-size: 50px;
  color: red;
  margin: 0 5px 5px 20px;
}
.page_tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.page_tree_search {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border: 1px solid #bdb8b8;
  border-bottom: none;
}

.sourt {
  width: 100%;
  display: flex;
  justify-content: center;
}
.sourt_btn {
  width: 100%;
  display: flex;
  /* flex-direction: row-reverse; */
}
.beatch {
  width: 90%;
}

.no_learn {
  width: 80%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.drawer_body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.steps_modal {
  /* background: gold; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps_modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.modal_body_okicon {
  width: 50px;
  height: 50px;
  background: #19bc89;
  font-size: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin: 20px 0;
}
.steps_modal_body button {
  margin-top: 20px;
  color: #19bc89;
  border: 1px solid #19bc89;
}

.page_icon_tab {
  width: 100%;
  background: rgb(253, 234, 234);
  display: flex;
  align-items: center;
  border: 1px solid rgb(223, 100, 100);
  color: rgb(223, 100, 100);
  border-radius: 5px;
}

.page_datashare_together {
  width: 100%;
  height: 50px;
  display: flex;
  margin-left: 100px;
}
/* // */
.newdatashare_page {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.newdatashare_page_sharedata {
  padding: 30px 0;
}
.newdatashare_page_sharedata {
  display: flex;
  align-items: center;
}

.newdatashare_page_datasource {
  padding: 30px 0;
}
.newdatashare_page_receive_device {
  padding: 30px 0;
}
.newdatashare_page_nodata {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.page_card_head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
}

.newdatashare_page_steps_modal {
  /* background: gold; */
  display: flex;
  align-items: center;
  justify-content: center;
}
.newdatashare_page_modal_body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.newdatashare_page_okicon {
  width: 50px;
  height: 50px;
  background: #19bc89;
  font-size: 40px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  margin: 20px 0;
}
