.account_page {
  width: 100%;
  height: 100%;
}

.account_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
}

.account_search_body {
  width: calc(100% - 300px);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.account_search_body_child {
  padding: 5px 20px 5px 0;
}

.account_search_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.account_search_button button {
  margin-left: 20px;
}

.account_menu_title {
  display: flex;
  align-items: center;
}

/* :global(.ant-form-item-control .ant-form-item-control-input .account_select) {
  background: red;
  min-height: 20px !important;
} */

.account_upload_btn {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.account_upload_btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.account_upload_icon {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(169, 167, 167);
}
.account_upload_img {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(240, 237, 237);
}
.account_form_option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.account_form_optionname {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.account_table_a a {
  margin-right: 20px;
}
.account_form{
  max-height: 300px;
  overflow-y: auto;
}
.form_option_body{
  display: flex;
  align-items: center;
  height: 40px;
}
.option_body{
  margin-left: 10px;
  display: flex;
}
.option_body div{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center
}
.option_body_add{
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #2EB998;
  color: #2EB998;
  cursor: pointer;
  margin-right: 5px;
}
.option_body_reduce{
  border-radius: 4px 4px 4px 4px;
  border: 1px solid #F56C6C;
  color: #F56C6C;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center
}
.form_body{
  width: 80%;
  border: 1px solid #DCDFE6;
  border-radius: 5px;
  max-height: 290px;
  overflow-y: auto;
}
