.goods_manage_body {
  width: 100%;
  height: 100%;
}

/* 顶部卡片查询提 */
.goods_manage_body_card {
  width: 100%;
  display: flex;
}
.goods_manage_body_card_search {
  width: calc(100% - 300px);
  display: flex;
  flex-wrap: wrap;
}
.goods_manage_body_card_search_child {
  padding: 5px 0;
  margin-right: 20px;
}
.goods_manage_body_card_btn {
  width: 300px;
  padding: 5px 0;
}

/* 主体内容 */
.goods_manage_body_uploadbtn {
  background: rgba(46, 185, 152, 0.06);
  color: #2eb998;
  margin-bottom: 12px;
  margin-right: 20px;
}
.goods_manage_body_delete {
  background: rgba(245, 108, 108, 0.06);
  color: #f56c6c;
  margin-bottom: 12px;
}

.goods_manage_body_head {
  width: 100%;
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: gold; */
}

/* .goods_manage_body_head_input input {
  width: 176px;
  margin-right: 20px;
} */
/* .goods_manage_body_head .ant-select {
  width: 172px;
  height: 40px;
} */

/* .goods_manage_body_search_btn button {
  opacity: 1;
  margin-left: 10px;
} */

/*                        */
.goods_manage_body_col_left {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.goods_manage_body_col_right {
  display: flex;
  align-items: center;
}

.goods_manage_body_tab {
  width: 80px !important;
  height: 35px !important;
  background: #ffffff !important;
  box-shadow: 0px -2px 4px 1px rgba(214, 214, 214, 0.16) !important;
  border-radius: 2px 0px 0px 2px !important;
  opacity: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.goods_manage_body_table_btn {
  color: #19bc89;
  padding: 0 10px;
}
.goods_manage_body_table_img {
  width: 160px;
  height: 90px;
  background: white;
  border-radius: 4px 4px 4px 4px;
  position: relative;
}
.tabimg_img_edit {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
  z-index: 999;
}
.modal_text {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal_text_icon {
  font-size: 20px;
  color: #e6a23c;
  margin-right: 10px;
}
.goods_manage_body_table_img img {
  height: 100%;
}

.goods_manage_body_goodmodal {
  /* width: 550px; */
  max-height: 550px;
  /* background: gold; */
  overflow: auto;
}
.sbcpsjdlj {
  width: 100%;
  position: relative;
  margin-top: 50px !important;
}
.goods_manage_body_goodmodal .ant-upload-select {
  color: red !important;
  position: absolute !important;
  right: 0 !important;
  top: -30px;
}
.goods_manage_body_goodmodal .ant-upload-list-item {
  margin-bottom: 5px !important;
}
.sbcpsjdlj_span {
  position: absolute !important;
  left: 0 !important;
  top: -30px;
}
.ant-upload-list {
  max-height: 200px !important;
  overflow: auto !important;
  border: 1px solid rgb(213, 209, 209) !important;
  margin-top: 20px;
}
.ant-upload-list-item-container {
  padding: 0 10px;
}
.ant-upload-list-webkit-scrollbar {
  width: 1px !important;
}

.goods_manage_body_goodmodal_hand {
  width: 100%;
  height: 182px;
  /* background: pink; */
  display: flex;
  align-items: center;
}

.goodmodal_hand_left {
  width: 109px;
  height: 109px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #19bc89;
  background: #ffffff;
  box-shadow: 0px 0px 6px 1px rgba(135, 135, 135, 0.16);
  border-radius: 4px 4px 4px 4px;
  margin-left: 3px;
}
.goodmodal_hand_right {
  height: 109px;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-left: 34px;
}
.goodmodal_hand_right div {
  margin-top: 7px;
}
.goods_manage_body_goodmodal_btn {
  width: 750px !important;
  background: #19bc89;
  height: 42px;
}
.goods_manage_body_goodmodal_imgnum {
  width: 100%;
  height: 186px;
  background: #ffffff;
  border-radius: 2px 2px 2px 2px;
  opacity: 1;
  border: 1px solid #eeeeee;
  overflow: auto;
  margin-top: 10px;
}

.goodmodal_foot {
  width: 100%;
  height: 80px;
  /* display: flex;
  justify-content: space-between; */
  position: relative;
  bottom: 0;
}
.sbcp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.goodmodal_foot_left {
  width: 40%;
  height: 100%;
  font-size: 12px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: 400;
  color: #999999;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.goodmodal_foot_right {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  font-size: 12px;
}

.delete_verifi {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.delete_verifi_body {
  width: 100%;
  height: 100%;
  padding: 20px;
}
.img_code {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}
.img_code_img {
  width: 100px;
  height: 100%;
}
.img_code_img img {
  width: 100%;
}
.img_code input {
  width: 70%;
}
.img_code button {
  width: 100px;
}

.icon_edit_body {
  width: 100%;
  display: flex;
}
.icon_modal_left {
  width: 100px;
  height: 100px;
  position: relative;
}
.icon_modal_title {
  position: absolute;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  color: white;
  cursor: pointer;
  z-index: 999;
  display: flex;
  justify-content: center;
  font-size: 14px;
}
.icon_modal_left img {
  width: 100%;
  height: 100%;
}
.icon_modal_right {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 0 20px;
}
.icon_modal_right span {
  padding-top: 11px;
}

.goodmodal_again_foot {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}
.goodmodal_again_right button {
  margin-left: 10px;
}
.goods_manage_body_again_title {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(102, 102, 102, 0.85);
}

.modal_tree_left_body {
  width: 100%;
  display: flex;
  justify-content: space-around;
  margin-top: 10px;
}
.tree_left_body {
  width: 40%;
  height: 500px;
  border: 1px solid #cec8c8;
}
/* .second_left_head {
  width: 100%;
} */
.left_head_body {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #cec8c8;
}
.left_head_body input {
  width: 60%;
  margin-right: 10px;
}
.tree_left_btn {
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.tree_left_btn button {
  font-size: 11px;
}

.left_tree {
  padding: 10px 10px 10px 0;
}

.goods_manage_body_equipment {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tree_left_body {
  width: 40%;
  height: 400px;
}
.tree {
  height: 340px;
  padding: 10px 10px 10px 10px 0;
  overflow: auto;
}
.treeSubOrClearBtn {
  width: 20%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tree_right_body {
  width: 40%;
  height: 400px;
  overflow: none;
  border: 1px solid #bdb8b8;
}
.goods_manage_body_treeright_child {
  padding: 5px 10px;
  height: 35px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.goods_manage_body_rigtitle {
  height: 35px;
  display: flex;
  align-items: center;
}
.goods_manage_body_online {
  padding: 0 5px 5px 10px;
}
.goods_manage_body_tree_nodate {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(196, 192, 192);
}
.goods_manage_body_onlien_status {
  display: flex;
  height: 20px;
  align-items: center;
  margin-left: 20px;
}
.goods_manage_body_online1 {
  font-size: 50px;
  margin-right: 5px;
  color: #1890ff;
  margin-bottom: 5px;
}
.goods_manage_body_online2 {
  font-size: 50px;
  color: red;
  margin: 0 5px 5px 20px;
}
.goods_manage_body_tags {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
}
.goods_manage_body_tree_search {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid #bdb8b8;
}

.sourt {
  width: 100%;
  display: flex;
  justify-content: center;
}
.sourt_btn {
  width: 100%;
  display: flex;
  /* flex-direction: row-reverse; */
}
.beatch {
  width: 90%;
}
.goodmanage_modal_img {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.goodmanage_modal_title {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(255, 224, 224);
  color: red;
}
.goodmanage_modal_count {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.goodmanage_modal_image {
  width: 100%;
  height: 300px;
  border: 1px solid rgb(213, 209, 209);
  display: flex;
  align-items: center;
  justify-content: center;
}
.goodmanage_modal_image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.goodmanage_modal_title1 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(255, 224, 224);
  color: red;
  margin: 20px 0;
}
.svcpftp {
  width: 100%;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(213, 209, 209);
  border-top: none;
}
.goods_manage_body_btns {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.goods_manage_body_btns_child button {
  margin-right: 20px;
}
.goods_manage_delete_btn {
  background: rgba(245, 108, 108, 0.06);
  color: #f56c6c;
  border-color: #f56c6c;
}
.goods_manage_status_btn {
  background: rgba(46, 185, 152, 0.06);
  color: #2eb998;
  border-color: #2eb998;
}
