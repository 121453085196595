.device_list_head {
  display: flex;
  justify-content: space-between;
}

.device_list_head_search {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.device_list_head_search_input_or_select {
  padding: 5px 20px 5px 0;
}
.device_list_head_right {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.device_list_head_right button {
  margin-left: 20px;
}
.page_table_list{
  width: 100%;
  position: relative;
}
.page_save{
  width: 500px;
  position: absolute;
  bottom: 20px;
  left: 0;
}
