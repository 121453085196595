.role_page {
  width: 100%;
  height: 100%;
}

.role_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
}

.role_search_body {
  width: calc(100% - 300px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.role_search_body_child {
  padding: 5px 20px 5px 0;
}

.role_search_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.role_search_button button {
  margin-left: 20px;
}

.role_menu_title {
  display: flex;
  align-items: center;
}

.role_table_but a {
  margin-right: 20px;
}
