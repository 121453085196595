.policeroad_page {
  width: 100%;
  height: 100%;
}

.updateroad_policeroad_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
}
.updateroad_policeroad_search_body {
  width: calc(100% - 300px);
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.updateroad_policeroad_search_body_child {
  padding: 5px 20px 5px 0;
}
.updateroad_policeroad_search_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.updateroad_policeroad_search_button button {
  margin-left: 20px;
}
.policeroad_page_body {
  width: 100%;
}
.policeroad_img {
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.policeroad_img img {
  width: auto;
  height: 100%;
  object-fit: contain;
  background: rgb(230, 227, 227);
}
.policeroad_table_img img {
  width: 100%;
  height: 40px;
  object-fit: contain;
  cursor: pointer;
}
