.lable_page {
  width: 100%;
  height: 100%;
}
.lable_page_head {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.lable_head_search {
  display: flex;
  align-items: center;
}
.lable_page_operate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.lable_operate_del {
  height: 100%;
  display: flex;
  align-items: center;
}
.lable_list {
  width: 100%;
  height: calc(100% * 0.88) !important;

  /* display: flex; */
}
.lable_list_data_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.lable_list_data_body_noInfo{
  width: 100%;
  display: flex;
  color: #d9d9d9;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.lable_list_data_body_item {
  width: 320px;
  margin: 0 0 10px 0;
}
.lable_list_data_body_item_null {
  width: 320px;
  margin: 0 0 10px 0;
}

.page_upload_btn {
  width: 148px !important;
  height: 148px !important;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  border: 1px dashed #dcdfe6;
}
.page_upload_btn img {
  max-width: 100%;
  height: 100%;
  object-fit: contain;
}
.lable_body_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.lable_info_top {
  width: 90%;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.lable_top_num span {
  box-sizing: border-box;
  padding: 0 5px;
}
.lable_info_bottom {
  width: 90%;
  height: 30px;
  align-items: center;
  justify-content: space-around;
}
.lable_del {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}
.lable_form_img {
  width: 300px;
  height: 150px;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 0 0 3px 3px rgb(240, 237, 237);
}
.lable_form_img img {
  width: auto;
  height: 100%;
}
.lable_form_label .ant-radio-wrapper {
  display: flex !important;
  align-items: center !important;
  margin: 10px 0 !important;
}
.lable_form_label .ant-radio-group {
  max-height: 200px;
  display: flex;
  flex-wrap: wrap;
  margin-left: 120px;
  overflow-y: scroll;
}
.lable_form_radioimg {
  width: 100px;
  height: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 0 0 3px 3px rgb(240, 237, 237);
}
.lable_form_radioimg img {
  width: auto;
  height: 100%;
}
.page_upload_icon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.page_form_xing {
  color: red;
  margin: 0 3px -10px 0 !important;
}

.lable_list_data {
  width: 100%;
  height: 260px;
  border: 1px solid #d7dfe9;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}
.lable_list_data_top_body {
  width: 100%;
  height: 195px;
}
.lable_list_data_top {
  width: 100%;
  height: calc(100% - 30px);
  border-bottom: 1px solid #d7dfe9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lable_list_data_top1 {
  width: 100%;
  height: calc(100% - 30px);
  border-bottom: 1px solid #d7dfe9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lable_list_data_top_check {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: #f5f5f5;
}
.lable_list_data_top img {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  object-fit: contain;
}
.lable_list_data_top1 img {
  width: 70%;
  height: 100%;
  padding-bottom: 30px;
  object-fit: contain;
}
.lable_list_data_bottom {
  width: 100%;
  height: calc(100% - 195px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.lable_list_data_bottom_skuName {
  width: calc(100% - 10px);
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.lable_list_data_function {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.lable_list_data_pagination {
  display: flex;
  justify-content: flex-end;
}

.lable_list_body {
  width: 100%;
  height: 180px;
  box-shadow: 0 0 3px 3px rgb(240, 237, 237);
  margin-bottom: 40px;
}
.lable_body_upload {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 4px 0 rgb(240, 237, 237);
}

.lable_list .ant-list-pagination {
  margin: 0 !important;
  padding: 0 !important;
}

.example_img {
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}
.example_img img {
  width: 148px;
  object-fit: cover;
  border-radius: 8px;
}
.lable_list_color img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}
.lable_list_pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
}
