.shop_goods_info_head {
  display: flex;
  /* align-items: center; */
}
.shop_goods_info_head_search {
  width: calc(100% - 180px);
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}

.shop_goods_info_head_btn {
  width: 180px;
  display: inline-flex;
  justify-content: flex-end;
  gap: 20px;
}

/* 学习图片下发 */
.pageModal{
  width: 100%;
  max-height: 500px;
  display: flex;
  gap: 20px;
  
}
.pageModalChild{
  width: calc(calc(100% - 20px)/2);
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.pageModalChildHead{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap:5px 20px;
  font-family: OPPOSans, OPPOSans;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  text-transform: none;
  min-height: 50px;
}
.pageModalChildHeadSpan{
  color: #999999;
}
.pageModalChildHeadDiv{
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
.pageModalChildHeadDiv div{
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
}

.pageModalChildImgBody{
  width: 100%;
  height: 400px;
  border-radius: 8px ;
  border: 1px solid #EBEEF5;
  display: flex;
  align-items: center;
  justify-content: center;
}
.pageModalChildImgBodyDiv{
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  background: #F5F7FA;
  border-radius: 4px;
  position:relative;
}
.pageModalChildImgBodyDivLeBtn{
  width: 40px;
  height: 40px;
  background: rgba(46, 185, 152, 0.2);
  position: absolute;
  left: 20px;
  top:calc(calc(100%  - 40px)/2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pageModalChildImgBodyDivRiBtn{
  width: 40px;
  height: 40px;
  background: rgba(46, 185, 152, 0.2);
  position: absolute;
  right: 20px;
  top:calc(calc(100%  - 40px)/2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.pageModalChildImgBodyDivNew{
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  /* background: #F5F7FA; */
  border-radius: 4px;
  display: flex;
  /* flex-direction: column; */
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  overflow: auto;
}
.pageModalChildImgBodyDivNewItem{
  width: calc(calc(100% - 20px) / 3 );
  height: calc(calc(100% - 20px) / 3 );
  background: #F5F7FA;
  position: relative;
  overflow: hidden;
}
.pageModalCheck{
  position: absolute;
  bottom: 10px;
  right: 10px;
}
.pageModalRightHead{
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 5px 20px;
}
.pageModalRightHeadNum{
  font-family: OPPOSans, OPPOSans;
  font-weight: 500;
  font-size: 12px;
  color: #333333;
  font-style: normal;
  text-transform: none;
}
.pageModalChildFoot{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  min-height: 50px;
  font-family: OPPOSans, OPPOSans;
  font-weight: 400;
  font-size: 12px;
  color: #999999;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.noData{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20;
  color:#ccc ;
  gap: 10px;
}

