.configdata_head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.configdata_add {
  display: flex;
  margin-bottom: 20px;
}
.configdata_tag {
  height: 32px;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.configdata_caozuo a {
  padding: 0 5px;
}
.configdata_caozuo_icon {
  color: #e6a23c;
  margin-right: 5px;
}
.configdata_table_select_body {
  width: 100%;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  line-height: 30px;
}
.configdata_table_select_icon {
  /* margin: 0 10px; */
  color: rgb(138, 136, 136);
}
.configdata_table_select_div {
  cursor: pointer;
}
