.account_page {
  width: 100%;
  height: 100%;
}

.account_page_search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  /* margin-bottom: 20px; */
  flex-wrap: wrap;
}

.account_search_body {
  width: calc(100% - 300px);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.account_search_body_child {
  padding: 5px 20px 5px 0;
}

.account_search_button {
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.account_search_button button {
  margin-left: 20px;
}

.account_menu_title {
  display: flex;
  align-items: center;
}

/* :global(.ant-form-item-control .ant-form-item-control-input .account_select) {
  background: red;
  min-height: 20px !important;
} */

.account_upload_btn {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.account_upload_btn img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.account_upload_icon {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(169, 167, 167);
}
.account_upload_img {
  width: 120px;
  height: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgb(240, 237, 237);
}
.account_form_option {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.account_form_optionname {
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
