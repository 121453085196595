.loss_analysis_head_right {
  display: flex;
  justify-content: flex-end;
}
.loss_analysis_head_right button {
  margin-left: 20px;
}
.loss_analysis_chart {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
}
.loss_analysis_chartleft {
  width: 100%;
  display: flex;
}
.loss_analysis_chartleftone {
  width: 50%;
}
.loss_analysis_chartlefttwo {
  width: 50%;
}
.loss_analysis_chartlefttwo_title {
  display: flex;
}
.loss_analysis_chartlefttwo_title_sort {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #909399;
  margin-left: 5px;
  font-size: 15px;
}

.loss_analysis_chartleftone_body {
  display: flex;
  flex-wrap: wrap;
  margin: 5px 0;
}
.chartleftone_body_title {
  width: 150px;
  padding: 5px 0;
}
.chartleftone_body_text {
  margin-right: 20px;
  padding: 5px 0;
}
.shopDetail_page_time {
  display: flex;
  margin-left: 10px;
}