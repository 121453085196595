.data_share {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.drawer_body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.drawer_body img {
  width: 50%;
  padding: 10px;
}
.loading {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.page_icon_img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 70px;
}
.page_icon_imgchild {
  width: 30%;
  height: 100%;
  background: rgb(243, 236, 236);
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.page_icon_imgchild img {
  max-width: 100%;
  height: 100%;
}
