.device_list_head {
  display: flex;
  justify-content: space-between;
}

.device_list_head_search {
  width: 85%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.device_list_head_search_input_or_select {
  padding: 5px 20px 5px 0;
}
.device_list_head_right {
  display: flex;
  justify-content: flex-end;
  padding: 5px 0;
}
.device_list_head_right button {
  margin-left: 20px;
}

.device_list_modal_edit_device {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.device_list_modal_edit_device_child {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.device_list_modal_edit_device_childname {
  width: 100px;
  display: flex;
  flex-direction: row-reverse;
}
.device_list_modal_edit_device_childname span {
  margin-right: 5px;
  color: #f56c6c;
}
.device_list_modal_edit_device_child .ant-input-affix-wrapper {
  border: 1px solid #dcdfe6 !important;
}

.device_list_caozo_btn {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.device_list_caozo_btn button {
  margin-right: 20px;
}
.devicemanage_page_store_table_btn {
  display: flex;
}
.devicemanage_page_store_table_btn a {
  padding: 0 5px;
}
.device_table_info {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
