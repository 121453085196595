.xappmanage_store_head {
  display: flex;
  align-items: center;
}
.xappmanage_store_head_search {
  width: calc(100% - 300px);
  display: flex;
}
.xappmanage_store_head_search_child {
  padding: 5px 20px 5px 0;
}
.xappmanage_store_head_btn {
  width: 300px;
  display: flex;
  justify-content: flex-end;
}
.xappmanage_store_head_btn button {
  margin-left: 20px;
}
