.shopurllirrary_page {
  width: 100%;
  height: 100%;
}
.shopurllirrary_page_head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.shopurllirrary_head_search {
  display: flex;
  align-items: center;
}
.shopurllirrary_page_operate {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.shopurllirrary_operate_del {
  height: 100%;
  display: flex;
  align-items: center;
}
/* .shopurllirrary_operate_del button {
  background: red;
  color: white;
} */
.shopurllirrary_operate_btn {
  height: 100%;
  display: flex;
  align-items: center;
}

.shopurllirrary_list {
  width: 100%;
  background: red;
}

.shopurllirrary_list .ant-list-pagination {
  height: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.shopurllirrary_list_data_body {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.shopurllirrary_list_data_body_item {
  width: 320px;
  margin: 0 0 10px 0;
}

.shopurllirrary_list_data_body_item_null {
  width: 320px;
  height: 0;
  margin: 0 0 10px 0;
}
.shopurllirrary_list_data_body_empty{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 50px;
}

.shopurllirrary_list_data {
  width: 100%;
  height: 260px;
  border: 1px solid #d7dfe9;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
}
.shopurllirrary_list_data_top_body {
  width: 100%;
  height: 195px;
}
.shopurllirrary_list_data_top {
  width: 100%;
  height: calc(100% - 30px);
  border-bottom: 1px solid #d7dfe9;
  display: flex;
  align-items: center;
  justify-content: center;
}
.shopurllirrary_list_data_top_check {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  background: #f5f5f5;
  border-radius: 8px 8px 0 0;
}
.shopurllirrary_list_data_top img {
  width: 100%;
  height: 100%;
  padding-bottom: 30px;
  object-fit: contain;
}
.shopurllirrary_list_data_bottom {
  width: 100%;
  height: calc(100% - 195px);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.shopurllirrary_list_data_bottom_skuName {
  width: calc(100% - 10px);
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
}
.shopurllirrary_list_data_function {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 8px;
}
.shopurllirrary_list_data_pagination {
  display: flex;
  justify-content: flex-end;
}

.shopurllirrary_list_body {
  width: 100%;
  height: 180px;
  box-shadow: 0 0 3px 3px rgb(240, 237, 237);
  margin-bottom: 40px;
}
.shopurllirrary_body_upload {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 4px 0 rgb(240, 237, 237);
}

.page_upload_btn {
  width: 100% !important;
  height: 100px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  /* box-shadow: 0 0 3px 3px rgb(240, 237, 237); */
  /* background: gold; */
}
.page_upload_layer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  opacity: 0.5;
  cursor: pointer;
}
.page_upload_btn img {
  max-width: 90%;
  height: 100%;
}
.shopurllirrary_body_info {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.shopurllirrar_info_top {
  width: 90%;
  height: 30px;
  display: flex;
  align-items: center;
}
.shopurllirrar_top_num {
  width: 20%;
  color: white;
  background: #2eb998;
  font-size: 11px;
  border-radius: 3px;
  box-sizing: border-box;
  text-align: center;
  /* display: flex;
  align-items: center;
  justify-content: center; */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.shopurllirrar_top_num span {
  box-sizing: border-box;
  padding: 0 5px;
}
.shopurllirrar_top_title {
  /* width: 80%; */
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-left: 10px;
}
.shopurllirrar_info_bottom {
  width: 90%;
  height: 30px;
  align-items: center;
  justify-content: space-around;
}
.shopurllirrar_del {
  width: 100%;
  height: 100%;
  display: flex;
  align-content: center;
  color: #606266;
  /* justify-content: center; */
}
.shopurllirrar_form_img {
  width: 300px;
  height: 150px;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 0 0 3px 3px rgb(240, 237, 237);
}
.shopurllirrar_form_img img {
  max-width: 100%;
  height: 100%;
}
.shopurllirrar_form_label_radio{
  max-height: 200px;
  overflow-y: scroll;
  margin-top: 40px;
}
.shopurllirrar_form_label .ant-radio-wrapper {
  display: flex !important;
  align-items: center !important;
  margin: 10px 0 !important;
}
.shopurllirrar_form_label .ant-radio-group {
  max-height: 200px;
  display: flex;
  flex-wrap: wrap;
  /* margin-left: 120px; */
  margin-top: 50px;
  overflow-y: scroll;
}
.shopurllirrar_form_input {
  position: relative;
  left: 147px;
  top: 30px;
  z-index: 9999 !important;
}

.shopurllirrar_form_radioimg {
  width: 100px;
  height: 50px;
  display: flex;
  align-content: center;
  justify-content: center;
  box-shadow: 0 0 3px 3px rgb(240, 237, 237);
  background: gold;
}
.shopurllirrar_form_radioimg img {
  max-width: 100%;
  height: 100%;
}
/* /批量导入/ */
.page_goodmodal {
  /* width: 550px; */
  max-height: 550px;
  /* background: gold; */
  overflow: auto;
}
.sbcpsjdlj {
  width: 100%;
  position: relative;
  margin-top: 50px !important;
}
.page_goodmodal .ant-upload-select {
  color: red !important;
  position: absolute !important;
  right: 0 !important;
  top: -30px;
}
.page_goodmodal .ant-upload-list-item {
  margin-bottom: 5px !important;
}
.goodmanage_modal_title1 {
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(255, 224, 224);
  color: red;
  margin: 20px 0;
}
.sbcpsjdlj_span {
  position: absolute !important;
  left: 0 !important;
  top: -30px;
}

.svcpftp {
  width: 100%;
  height: 370px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(213, 209, 209);
  border-top: none;
}
.goodmodal_foot {
  width: 100%;
  height: 80px;
  /* display: flex;
  justify-content: space-between; */
  position: relative;
  bottom: 0;
}
.sbcp {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
}

.shopurllirrary_img {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 3px 3px rgb(240, 237, 237);
}
.shopurllirrary_img img {
  width: 100%;
  height: 90%;
  object-fit: contain;
}

.shopurllirrary_pagination {
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
}
