html,
body,
#root,
.ant-layout {
  width: 100%;
  height: 100%;
}
body {
  overflow: auto;
  min-width: 1000px;
  min-height: 600px;
}
/* .ant-select-selector {
  padding: 18px !important;
  display: flex !important;
  align-items: center !important;
}
.ant-select-selector .ant-select-selection-search {
  display: flex !important;
  align-items: center !important;
} */

/* ::-webkit-scrollbar {
  width: 10px;
  overflow: auto;
} */
/* .ant-input {
  padding: 0 11px !important ;
}
.ant-select-selector {
  padding: 18px !important;
  display: flex;
  align-items: center;
}

.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-focused {
  color: rgb(255, 255, 255) !important;
  background: #2EB998 !important;
  border-color: #2EB998 !important;
}
.ant-btn-default:hover,
.ant-btn-default:focus,
.ant-btn-focused {
  color: rgb(255, 255, 255) !important;
  border: 1px solid #19bc89 !important;
  color: #19bc89 !important;
}
.ant-input:hover,
.ant-input:focus,
.ant-input-focused {
  border-color: #19bc89 !important;
}
.ant-select-selector:hover,
.ant-select-selector:focus,
.ant-select-selector-focused {
  border-color: #19bc89 !important;
}
.ant-radio-checked .ant-radio-inner .ant-radio-checked:hover,
.ant-radio-checked:focus,
.ant-radio-checked-focused {
  background: #19bc89 !important;
  border-color: #19bc89 !important;
}

.ant-radio-inner:hover,
.ant-radio-inner-focus,
.ant-radio-inner:focus,
.ant-radio-inner::after {
  background-color: #19bc89 !important;
} */
::-webkit-scrollbar {
  height: 12px;
  width: 0.1px;
  overflow-y: auto;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background: #939392;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: 0;
  border-radius: 0.1px;
  background: #f0f2f5;
}

.ant-input-affix-wrapper:hover {
  border-color: #19bc89 !important;
  border: 1px solid #19bc89 !important;
}

.ant-input-affix-wrapper:focus {
  border-color: #19bc89 !important;
  border: 1px solid #19bc89 !important;
}

.ant-input-affix-wrapper-focused {
  border-color: #19bc89 !important;
  border: 1px solid #19bc89 !important;
}
