.device_page {
  width: 100%;
  height: 100%;
}
.device_head_info {
  width: 100%;
  display: flex;
}
.device_info_child {
  width: 300px;
  /* background: gold; */
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 5px rgb(216, 213, 213);
  border-radius: 5px;
  margin-right: 20px;
}
.device_child_body {
  width: 270px;
  height: 130px;
}
.device_child_top {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.device_child_top .ant-select-selector {
  padding: 0 10px !important;
  display: flex;
  align-items: center;
}

.device_child_bottom {
  width: 100%;
  height: 80px;
  display: flex;
  color: rgb(82, 81, 81);
}
.device_bottom_title {
  height: 40px;
  display: flex;
  flex-direction: column-reverse;
  color: rgb(162, 164, 165);
}
.device_bottom_value {
  width: 135px;
  height: 40px;
  /* display: flex;
  align-items: center; */
  line-height: 40px;
  font-size: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.device_body {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 200px;
}
.device_echarts {
  width: 49%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0 0 5px 5px rgb(216, 213, 213);
  margin-top: 40px;
  border-radius: 5px;
}
.device_echarts_body {
  width: 45vw;
  height: 300px;
}
.device_echarts_title {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}
